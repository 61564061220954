// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { Component } from "vue-property-decorator";

@Component({})
export default class contenitoreEmptyPage extends baseContextComponent {
	transitionName: string = "fade-in-right";
	// get transitionName(): string {
	// 	return this._transitionName;
	// }
}
